.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #4dd0e1; */
  background-color: #1E1E1E;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slug-width{
  width: 40%;
}

.domain-input{
  width: 37%;
}

.subscription-card{
  width: 271px;
  height: 280px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  background: #FFFFFF;
  border:1px solid #DCDBDC;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

@media only screen and (max-width: 480px) {
  .slug-width{
      width: 100%;
  }
  .domain-input{
    width: 70%;
  }
  .subscription-card{
    width: inherit;
    margin-right: 1.4rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    background: #FFFFFF;
    border: 1px solid #DCDBDC;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
}
