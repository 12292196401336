.site-card{
    width: 100%;
    height: 220px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4;
    background: #FFFFFF;
    cursor: pointer;
    display: flex;
    flex-direction: row;
}

.site-card.selected {
    border: 3px solid #6DBCB6;
    box-sizing: 'border-box';
    border-radius: 4px;
    background-color: #DBEEED
}