@media (max-width: 599px) {
    .mobile-responsive thead,
    .mobile-responsive tbody,
    .mobile-responsive tr,
    .mobile-responsive td,
    .mobile-responsive th,
    .mobile-responsive {
        display: block;
        width: 100%;
    }
    
    .mobile-responsive tr {
        display: flex;
        flex-wrap: wrap;
    }
    .mobile-responsive tr th:nth-child(2), 
    .mobile-responsive tr td:nth-child(2) {
        width: calc(100% - 50px);
    }
    .mobile-responsive tr th:nth-child(3), 
    .mobile-responsive tr td:nth-child(3) {
        width: 50px;
    }
}