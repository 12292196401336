.organizationItem {
    display: flex;
    flex-wrap: wrap;
    min-height: 160px;
    margin: 12px;
}

.organizationItem .organizationItem__image {
    height: 160px;
    width: 160px;
    padding: 12px;
    object-fit: contain;
    color: #1F9DC1;
}

.organizationItem .organizationItem__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 12px;
    justify-content: center;
    align-self: flex-start;
}

.organizationItem .organizationItem__title {
    font-size: 1.3rem;
    color: #000;
    margin-bottom: 12px;
}

.organizationItem .organizationItem__provider {
    font-size: 0.8rem;
    color: '#555';
    font-weight: 600;
}

.organizationItem .organizationItem__description {
    font-size: 0.9rem;
    flex: 1;
    margin-top: 12px;
    overflow: hidden;
    max-height: 130px;
}

.organizationItem .organizationItem__btnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
}

.organizationItem .organizationItem__button {
    width: 220px;
    margin: 6px;
}
@media (max-width: 1199px){
    .organizationItem .organizationItem__content {
        width: 33.3%;
        flex: none;
    }
    .organizationItem .organizationItem__content.istitle {
        width: calc(100% - 160px);
        align-self: center;
        
    }
    .organizationItem .organizationItem__btnContainer {
        width: 33.3%;
    }
    .organizationItem .organizationItem__btnContainer:last-of-type {
        padding-bottom: 20px;
    }
}
@media (max-width: 991px){
    .organizationItem .organizationItem__btnContainer,
    .organizationItem .organizationItem__content {
        width: 50%;
    }
    .organizationItem .organizationItem__content.isContent {
        width: 100%;
    }
}
@media (max-width: 767px){
    .organizationItem {
        text-align: center;
    }
    .organizationItem .organizationItem__image {
        margin: 0 auto;
    }
    .organizationItem .organizationItem__btnContainer,
    .organizationItem .organizationItem__content {
        width: 100%;
    }
    .organizationItem .organizationItem__content.istitle {
        width: 100%;
    }
    .organizationItem .organizationItem__provider {
        font-weight: 400;
        margin: 5px 0;
    }
    .organizationItem .organizationItem__provider span {
        width: 100%;
        font-weight: 700;
        display: block;
    }
}